<template>
  <div class="flex items-center">
    <span v-if="isAvailable && status" class="text-xs text-oGreen">
      <i class="fas fa-check-circle" />
    </span>

    <span
      v-else
      class="inline-flex relative w-3 h-3 rounded-full"
      :class="getClass"
    />

    <div :class="textClass">
      {{ getText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeartbeatStatus',
  props: {
    status: { required: false, type: Boolean, default: false },
    text: { required: false, type: Boolean, default: true },
    textClass: {
      required: false,
      type: String,
      default: 'ml-2 font-medium text-oGray text-14px',
    },
    activeText: { required: false, type: String, default: 'Active' },
    inactiveText: { required: false, type: String, default: 'Inactive' },

    isAvailable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    getText() {
      return this.status ? this.activeText : this.inactiveText
    },
    getClass() {
      return this.status ? 'bg-oGreen' : 'bg-oGray'
    },
  },
}
</script>

<style></style>
